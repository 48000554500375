import { HOSTS, PROJECT_NAME, PROJECT_NAMES, ProjectName } from './env';

export const GENERAL_URLS = {
  staking: process.env.NEXT_PUBLIC_STAKING_HOST || 'https://magicsquare.io/staking',
  otc: 'https://spring.net/discover/magic-square',
  dao: 'https://dao.magicsquare.io',
  privacyPolicy: 'https://docs.magic.store/documents/legal-documents/privacy-policy',
  termsAndConditions:
    'https://docs.magic.store/documents/legal-documents/magic-store-terms-and-conditions',
};

export const getNextProjectsUrlGenerators = (currentProjectName?: ProjectName) => {
  const getUrlGetter = (projectName: ProjectName) => {
    return (path: string) => {
      if (currentProjectName === projectName) {
        return path;
      }

      return `${HOSTS[projectName]}${path}`;
    };
  };

  return {
    store: getUrlGetter(PROJECT_NAMES.STORE),
    staking: getUrlGetter(PROJECT_NAMES.STAKING),
    launchpad: getUrlGetter(PROJECT_NAMES.LAUNCHPAD),
    swap: getUrlGetter(PROJECT_NAMES.SWAP),
  };
};

export const nextProjectUrl = getNextProjectsUrlGenerators(PROJECT_NAME);
