// TODO: add types
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { Deserializer } from 'jsonapi-serializer';
import { isArray } from 'lodash';

// "other" is used in case if backend returns relationships items with some data,
// in that case we just copy data
// "relationships": {
//   "key": {
//     "data": [
//       {
//         "id": 1,
//         "type": "relationship",
//         "someData": true
//       },
//     ]
//   }
// }
const link = ({ id, type, ...other }, included) => {
  const data = included.find((el) => el.id === id && el.type === type);

  return {
    ...other,
    ...data,
  };
};

const linkArray = (jsonApiData, key, included) => {
  jsonApiData[key] = [];

  if (!jsonApiData.relationships) {
    return;
  }

  for (const resource of jsonApiData.relationships[key].data) {
    jsonApiData[key].push(link(resource, included));
  }

  delete jsonApiData.relationships[key];
};

const linkObj = (jsonApiData, key, included) => {
  jsonApiData[key] = {};
  jsonApiData[key] = link(jsonApiData.relationships[key].data, included);
  delete jsonApiData.relationships[key];
};

const checkExistRel = (item) => {
  if (Array.isArray(item)) {
    return item.some((el) => el?.relationships && Object.keys(el?.relationships).length > 0);
  }

  if (item?.constructor === Object) {
    return item?.relationships && Object.keys(item?.relationships).length > 0;
  }

  return false;
};

const linkRel = (jsonApiData, included) => {
  const { relationships } = jsonApiData;

  for (const key in relationships) {
    if (Array.isArray(relationships[key].data)) {
      linkArray(jsonApiData, key, included);

      if (checkExistRel(jsonApiData[key])) {
        if (Array.isArray(jsonApiData[key])) {
          for (const item of jsonApiData[key]) {
            linkRel(item, included);
          }
        } else if (jsonApiData[key]?.constructor === Object) {
          linkRel(jsonApiData[key], included);
        }
      }
    } else if (relationships[key].data) {
      linkObj(jsonApiData, key, included);

      if (checkExistRel(jsonApiData[key])) {
        if (checkExistRel(jsonApiData[key])) {
          if (Array.isArray(jsonApiData[key])) {
            for (const item of jsonApiData[key]) {
              linkRel(item, included);
            }
          } else if (jsonApiData[key]?.constructor === Object) {
            linkRel(jsonApiData[key], included);
          }
        }
      }
    }
  }

  delete jsonApiData.relationships;
};

const deserializeArray = (jsonApiData: JsonApiObject<JsonApiObjectData[]>) => {
  for (const item of jsonApiData.data) {
    if (item?.relationships && Object.keys(item?.relationships).length > 0) {
      linkRel(item, jsonApiData.included ?? []);
    }
  }
};

export type JsonApiObjectData = {
  id: number | string;
  type: string;
  attributes: {
    [key: string]: any;
  }[];
  relationships?: {
    [key: string]: object;
  }[];
  meta?: {
    [key: string]: any;
  };
};

export interface JsonApiObject<
  Data extends JsonApiObjectData | JsonApiObjectData[] = JsonApiObjectData | JsonApiObjectData[],
> {
  data: Data;
  included?: JsonApiObjectData[];
  meta?: object;
}

export interface DeserializedJsonApiObject<Data extends object> {
  data: Data;
}

export interface DeserializedData<Type extends string, Attributes extends object = object> {
  id: number;
  type: Type;
  attributes: Attributes;
  meta?: object;
}

export const deserialize = <T extends object>(
  jsonApiObject: JsonApiObject,
  addIncluded?: boolean,
): T => {
  const clonedJsonApiObject = JSON.parse(JSON.stringify(jsonApiObject));

  if (isArray(clonedJsonApiObject.data)) {
    deserializeArray(clonedJsonApiObject as JsonApiObject<JsonApiObjectData[]>);
  }

  if (
    clonedJsonApiObject?.included &&
    !isArray(clonedJsonApiObject.data) &&
    clonedJsonApiObject.data?.relationships
  ) {
    linkRel(clonedJsonApiObject.data, clonedJsonApiObject.included);
  }

  let data = clonedJsonApiObject.meta
    ? {
        ...clonedJsonApiObject.data,
        meta: JSON.parse(JSON.stringify(clonedJsonApiObject.meta)),
      }
    : clonedJsonApiObject.data;

  if (addIncluded) {
    data = {
      ...data,
      included: clonedJsonApiObject.included,
    };
  }

  return data as T;
};

export const deserializeJsonApi = <Data>(
  data: any,
  options?: DeserializerOptions,
): Promise<Data> => {
  return new Deserializer({
    keyForAttribute: 'camelCase',
    ...options,
  }).deserialize(data);
};
