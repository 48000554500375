import { GENERAL_URLS } from './urls';

export const DEFAULT_LAUNCHPAD_DATE_DISCLAIMER = 'GTM+00:00';

export const DEFAULT_EVM_ADDRESS_DESCRIPTION =
  'EVM address contains 42 characters, starting with "0x"';

export const LAUNCHPAD_INTERNAL_ROUTES = {
  apply: '/apply',
  account: '/portfolio',
  howItWorks: '/how-it-works',
  kyc: '/kyc',
  main: '/',
  project: (slug: string) => `/token-sales/${slug}`,
  tokenSales: '/token-sales',
  campaigns: '/campaigns',
  sale: (projectSlug: string, salePoolId: string) =>
    `/token-sales/${projectSlug}/sale/${salePoolId}`,
};

export const LAUNCHPAD_URL = {
  announcements: 'https://t.me/magicsquare_official/414752',
  magicLaunchpadTG: 'https://t.me/magicsquare_official/420103',
  magicLaunchpadTG2: 'https://t.me/magicsquare_official/150012',
  howToGet: 'https://docs.magic.store/magic-launchpad/getting-started',
  privacyPolicy: 'https://docs.magic.store/documents/legal-documents/privacy-policy',
  termsAndConditions:
    'https://docs.magic.store/documents/legal-documents/magic-launchpad-terms-and-conditions',
  telegram: 'https://t.me/magic_launchpad',
  discord: 'https://discord.gg/magicsquareio',
  x: 'https://twitter.com/magiclaunchpad',
  documentation: '#',
  apply: 'https://launchpad.magic.store/apply',
  staking: GENERAL_URLS.staking,
} as const;

//TODO: update links or remove due to unused component

export const GETTING_STARTED_URL = {
  whatIsLaunchpad: '#',
  howToStart: '#',
  whatIsSQRp: '#',
  whatIsVestedSale: '#',
  whatIsPublicSale: '#',
  riskReminder: '#',
} as const;

export const EXPLORE_PROJECT_URL = {
  howToJoinWhitelist: '#',
  howToTakeVestedSale: '#',
  howToTakePublicSale: '#',
  howToParticipateFCFS: '#',
  howToClaimToken: '#',
} as const;

export const ALL_BENEFITS_SQR_URL = {
  whereToBySQR: '#',
  whyToHoldSQR: '#',
  whatAreSQRp: '#',
  howToStakeSQR: '#',
  SQRTokenomics: '#',
} as const;
